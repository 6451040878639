@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'DMSans';
  src: url("./fonts/DMSans-Regular.ttf") format('truetype');
}

@font-face {
  font-family: 'DMSans Bold';
  src: url("./fonts/DMSans-Bold.ttf") format('truetype');
}

@font-face {
  font-family: 'DMSans Italic';
  src: url("./fonts/DMSans-Italic.ttf") format('truetype');
}

body {
  margin: 0;
  font-family: "DMSans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
}

.interests-index {
  background: url("./assets/png/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #000000;
  color: #ffffff;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  outline: none;
  border: none;
  background: linear-gradient(to right, #F14498, #D43DE4);
  padding: 7px 20px;
  border-radius: 4px;
  color: #ffffff;
  font-size: 22px;
  font-weight: bold;
  box-shadow: 0px 5px 34px rgba(186, 19, 88, 0.42);
  white-space: nowrap;
}

.navigation-button {
  background: linear-gradient(to right, #F14498, #D43DE4);
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}